<template>
  <div class="FollowedStreamsManagerStream is-flex p-a-m is-aligned-middle">
    <div class="is-width-full p-r-m">
      <div class="has-text-weight-semibold">
        {{ stream.title }}
      </div>
      <v-separator class="m-v-s" />
      <div>
        <clipping-type-icon
          v-for="type in stream.source_types"
          :key="type"
          :type="type"
        />
      </div>
    </div>
    <stream-follow-button
      v-model="stream.followed_by_user"
      :stream-id="stream.id"
      class="m-l-a"
      data-testid="FollowStreamButton"
      @change="$emit('follow-change', $event)"
    />
  </div>
</template>

<script>
import StreamFollowButton from '@/components/streams/StreamFollowButton'

/**
 * @module FollowedStreamsManagerStream
 */
export default {
  name: 'FollowedStreamsManagerStream',
  components: { StreamFollowButton },
  props: {
    /** @type HF_Stream */
    stream: {
      type: Object,
      required: true
    }
  }
}
</script>
